.echarts, .count {
	border: 1px solid #0067A6;
	padding: 20px;
	margin-top: 18px;
	border-radius: 8px;
}

.tit {
	width: 46%;
	height: 40px;
	background: linear-gradient(90deg, #0067a6 0%, rgba(0, 103, 166, 0) 100%);
	line-height: 40px;
	padding-left: 15px;
	border-radius: 8px 0 0 0;
	font-size: 17px;
	font-weight: bold;
	color: #FFFFFF;
}

.analysis {
	width: 70%;
	float: left;
}

.rightCount {
	margin-left: 18px;
	width: calc(100% - 63%);
	float: left;
}

.number {
	text-align: center;
	word-break: break-all;
	font-weight: bold;

	p {
		font-size: 40px;
		color: #19D4AE;
		margin: 20px 0 0;
	}

	span {
		font-size: 15px;
		color: rgba(255,255,255,0.5);
	}

	.inPark {
		color: #5AB1EF;
	}

	.outPark {
		color: #0067A6;
	}

	.loss {
		color: #FA6E86;
	}
	.lossCoupon{
		color: #FFB980;
	}
	.govermentLoss{
		color: #197ad4;
	}
	.coopLoss{
		color: #dc5bf0;
	}
	.employLoss{
		color: #19b2d4;
	}
}

.countAmount {
	justify-content: space-between;
}
.progressbar{
	margin: 20px auto;
	display: flex;
	align-items: center;
	justify-content: center;
	::v-deep .el-progress__text{
		font-size: 33px !important;
		font-family: Microsoft YaHei UI;
		font-weight: bold;
		color: #373D5B;
	}

}
.titPark{
	margin-top: 20px;
}
